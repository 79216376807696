<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container>

          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <v-stepper alt-labels
              elevation="0"
              >
                <v-stepper-header>
                  <v-stepper-step step="" class="active-step today-medium">
                    Póliza
                  </v-stepper-step>

                  <v-divider inset class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Simulador
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Formulario
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Firma de documentos
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Pago
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </v-col>
            <v-col class="text-center justify-center d-flex d-sm-none">
              <div class="text-h6 today-medium primary--text">
                Pólizas
              </div>
            </v-col>
            <v-col cols="12" class="scroll-container pt-0">

              <v-col class="text-center justify-center">
                <v-progress-circular
                    v-if="policies.length == 0"
                    :size="50"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
              </v-col>


              <v-list three-line >

                <v-card
                    class="mx-auto my-4"
                    max-width="550"
                    hover
                    outlined
                    style="border-color: #0E4A7D; border-radius: 12px;"
                    @click="goSimulator(policy.id)"
                    v-for="(policy, index) in policies"
                    :disabled="validatePolicyStatus(policy)">
                  <v-list-item>

                    <v-avatar class="d-none d-sm-flex mr-4" size="100" style="color: #0E4A7D; border: 3px solid;">
                      <v-img :src="policy.image"></v-img>
                    </v-avatar>

                    <v-list-item-content class="primary--text">
                      <v-list-item-title class="text-h4"><b class="today-regular">{{ policy.insurer }}</b></v-list-item-title>
                      <v-list-item-subtitle v-for="(item, indexItem) in policy.values" class="primary--text text-h6 line-height-1 mt-1">
                        <span class="today-regular">{{ item.name }} <b v-html="item.value"></b></span>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-avatar>
                      <v-icon color="primary" large>mdi-chevron-right</v-icon>
                    </v-avatar>
                  </v-list-item>

                </v-card>

              </v-list>

            </v-col>
          </v-row>

        </v-container>
      </v-col>
    </v-row>

    <v-dialog
        v-model="dialog"
        max-width="350"
        overlay-color="primary"
        persistent
    >
      <v-card class="pa-4 text-center">

        <v-img max-height="50"
               class="my-4"
               contain
               src="https://app-biocredit.s3.amazonaws.com/vardi/obediente.png"
        ></v-img>

        <h2 class="my-3 today-medium justify-center primary--text">{{ gender == 'M' ? 'BIENVENIDO' : 'BIENVENIDA' }} {{ name.toUpperCase() }}</h2>

        <h3 class="text-justify primary--text today-light line-height-1 px-4">
          A continuación encontrarás las pólizas que tienes vigentes. Selecciona una y empieza el proceso de financiación.
        </h3>

        <v-card-actions class="justify-center">
          <v-btn
              color="primary"
              class="px-10 mt-4 text-capitalize today-bold vardi-button"
              @click="dialog = false"
              elevation="0"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import {locals} from "@/helpers/locals";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";

export default {
  name: 'Policy',
  components: {LeftSideImage, NavBarPage},
  data() {
    return {
      dialog : false,
      user: null,
      name: '',
      gender: '',
      policies: [],
    }
  },
  created() {
    api.get(constants.endPoints.policies, true)
        .then(response => {
          this.policies = response.data.data;


          console.log(this.policies);
        });
  },
  mounted() {

    this.user = locals.getLocal('user');
    if(!this.dialog) {
      api.get(constants.endPoints.user, true)
          .then(response => {

            this.name = response.data.first_name;
            this.gender = response.data.gender;
            this.user.name = this.name;
            this.user.first_name = response.data.first_name;
            this.user.last_name = response.data.last_name;
            locals.setLocal('user', JSON.stringify(this.user));

            this.dialog = true;
            console.log(response);
          })
    }
  },
  methods: {
    goSimulator(policyId) {
      this.$router.push({
        name: 'Simulator',
        params: { insurancePolicyId: policyId}
      });
    },
    validatePolicyStatus(policy) {
      console.log('validatePolicyStatus', policy.values[3].value.search('Pagada'));
      return policy.values[3].value.search('Pagado') != -1;
    }
  }
}
</script>

<style lang="scss">
.v-stepper__label {
  font-size: 12px;
}

.active-step .v-stepper__label {
  color: #0E4A7D !important;
}

.active-step > span  {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
  background: #0E4A7D !important;
}

.grey-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin-top: 29px !important;
}

.active-separator-step {
  border-color: #0E4A7D !important;
}

.grey-separator-step {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.scroll-container {
  height: 500px;
  overflow: scroll;
  overflow-x: hidden;
}

.v-divider {
  border-width: 1px 2px 2px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 29px -82px 0 !important;
}

@media (max-width: 600px) {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 120px !important;
    padding: 5px !important;
  }
  .v-divider {
    visibility: hidden;
  }
}
</style>